<template>
    <div>
        <header class="relative">
            <div class="flex justify-between">
                <div>
                    <img src="/images/logo.webp" class="w-32 md:w-40 xl:w-60 h-8 md:h-12 xl:h-16 bg-white py-2 px-4 md:px-8 shadow-logoShadow rounded-customRadius flex items-center object-contain">
                </div>
                <div class="px-2 lg:px-4 2xl:px-8 gap-4 flex items-center">
                    <div class="w-36 hidden md:block">
                        <a :href="recruitClientUrl" target="_blank" class="flex justify-center items-center rounded h-9 px-3 border hover:border-gray-400 bg-white">
                            <img src="/images/smartrecruitnew.webp" class="bg-white rounded-full px-1 h-full w-full object-contain p-0.5">
                        </a>
                    </div> 
                    <div class="w-36 hidden md:block">
                        <a :href="plannerUrl" target="_blank" class="flex justify-center items-center rounded h-9 border hover:border-gray-400 bg-white">
                            <img src="/images/footer-planner.webp" class="bg-white rounded-full h-full w-full object-cover">
                        </a>
                    </div>  
                    <div class="w-36 hidden md:block">
                        <a :href="jiipaClientUrl" target="_blank" class="flex justify-center items-center rounded h-9 px-3 border hover:border-gray-400 bg-white">
                            <img src="/images/jiipa.webp" class="bg-white rounded-full px-1 h-full w-full object-contain p-0.5">
                        </a>
                    </div>  
                    <div class="relative flex justify-center items-center rounded h-9 px-1.5 border hover:border-gray-400 bg-white">
                        <button type="button" class="flex items-center gap-2" @click="dropLang = !dropLang">
                            <svg class="w-2.5 lg:w-4 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                            </svg>
                            <span>{{ languageName }}</span>
                            <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropLang }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="#000"/></svg>
                        </button>
                        
                        <div v-if="dropLang" class="absolute top-9 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-28 z-40">
                            <ul class="">
                                <li
                                    v-for="(language, index) in languages" :key="index"
                                    @click="selectLang(language.slug,language.name); dropLang = false"
                                    class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md">
                                    {{ language.name }}
                                </li>
                            </ul>
                        </div>
                        <button v-if="dropLang" @click="dropLang = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                        <!-- <div class="relative inline-flex w-28 md:w-32">
                            <svg class="w-1.5 absolute top-0 right-0 m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#fff" fill-rule="nonzero"/></svg>
                            <select class="w-full border border-gray-300 rounded-full text-white pl-4 pr-8 h-7 flex items-center font-semibold text-xs hover:border-gray-400 focus:outline-none appearance-none bg-bgGray" @change="languageHandler" v-model="locale" >
                                <option value="en">English</option>
                                <option value="jp">Japanese</option>
                            </select>
                        </div>  -->
                    </div> 

                    <!-- <div v-if="isAuthenticated">
                      <router-link :to="{ name: 'cart' }" active-class="activeCart" class="w-[40px] h-[40px] flex items-center justify-center">
                        <svg class="w-[20px] lg:w-[25px] h-[25px] transition-colors" :class="{ 'text-white bg-bgBlue': $route.name === 'cart', 'text-black fill-black': $route.name !== 'cart' }" fill="none" viewBox="0 0 32 25"><path d="M31.1024 4.56337c-.0931-.12102-.2128-.21909-.3497-.28669-.1369-.06761-.2876-.10295-.4403-.10331H7.21243l-.45-1.43c-.04943-.15355-.13527-.29285-.2502-.40603-.11492-.11319-.25552-.19689-.4098-.24397l-4.1-1.260004C1.87636.794626 1.7439.781096 1.6126.79355c-.1313.012453-.25886.050646-.3754.112397-.23535.124713-.411531.337813-.48977.592423-.078239.2546-.052131.52986.072581.76522.124711.23536.337809.41154.592419.48978l3.59 1.1 4.58 14.47003-1.63 1.34-.13.13c-.4031.4667-.63148 1.0591-.64608 1.6756-.01459.6166.18551 1.2191.56608 1.7044.27256.3314.61891.5945 1.01134.7681.39243.1737.82005.2531 1.24863.2319h16.69c.2652 0 .5196-.1054.7071-.2929.1876-.1876.2929-.4419.2929-.7071 0-.2653-.1053-.5196-.2929-.7071-.1875-.1876-.4419-.2929-.7071-.2929H9.84243c-.11515-.004-.22735-.0375-.32574-.0975-.0984-.0599-.17967-.1442-.23597-.2448-.05629-.1005-.08571-.2139-.08541-.3291.00029-.1152.0303-.2284.08712-.3286l2.40997-2h15.43c.2285.0066.4523-.0652.6342-.2034.182-.1383.3111-.3348.3658-.5566l3.2-13.00003c.0304-.14909.0264-.30314-.0117-.45044s-.1094-.28396-.2083-.39956Z"/>
                        </svg>
                      </router-link>
                    </div> -->
                </div>
            </div>
           
            <div id="scroll_top" class="fixed top-9 md:top-12 xl:top-16 z-30 w-full shadow-lg flex items-center justify-between bg-black bg-opacity-75 w-full transition-all duration-300 ease-in-out">
                <div class="w-full flex text-white items-center gap-3 xl:gap-5 uppercase font-semibold text-small lg:text-xs xl:text-sm 2xl:text-base tracking-wider">
                    <div class="w-full">
                        <div class="flex justify-between items-center md:divide-x md:divide-gray-300 text-center">
                            <a href="javascript:void(0)" @click="redirectBtn('')" active-class="active" class="hidden md:block w-full hover:bg-opacity-50 hover:bg-bgBlue py-3" >
                            <!-- Home -->{{ $t('home') }}
                            </a>
                            <a href="javascript:void(0)" @click="redirectBtn('/search')" active-class="active" class="hidden md:block w-full hover:bg-opacity-50 hover:bg-bgBlue py-3" >
                            <!-- Search -->{{$t('search')}}
                            </a>
                            <div class="hidden md:block relative flex justify-center items-center w-full">
                                <button type="button" class="w-full flex items-center justify-center gap-2 hover:bg-opacity-50 hover:bg-bgBlue py-3"
                                 @click.native="dropGallery = !dropGallery"
                                 v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('art-gallery') || isActive('art-users') || isActive('art-user-profile') || isActive('photo-gallery') || isActive('photographer-list') || isActive('photographer-profile') || isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile') || isActive('designers-gallery') || isActive('designers-list') || isActive('designers-profile')}"
                                 >
                                    <!-- <span v-if="galleryName">{{ galleryName }}</span> -->
                                    <span v-if="!isActive('art-gallery') && !isActive('art-user-profile') && !isActive('art-users') && !isActive('photo-gallery') && !isActive('photographer-profile') && !isActive('frame-gallery') && !isActive('frame-vendors') && !isActive('frame-vendor-profile') && !isActive('designers-gallery') && !isActive('designers-profile') && !isActive('designers-list')">
                                    <!-- Gallery -->{{$t('gallery ')}}
                                    </span>
                                    <span v-else-if="isActive('art-gallery') || isActive('art-user-profile') || isActive('art-users')">
                                    <!-- Art Gallery -->{{$t('art-gallery ')}}
                                    </span>
                                    <span v-else-if="isActive('photo-gallery') || isActive('photographer-profile')">
                                    <!-- Photo Gallery -->{{$t('photo-gallery ')}}
                                    </span>
                                    <span v-else-if="isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile')">
                                    <!-- Frame Gallery -->{{$t('frame-gallery')}}
                                    </span>
                                    <span v-else-if="isActive('designers-gallery')  || isActive('designers-list') || isActive('designers-profile')">
                                    <!-- Design Gallery -->{{$t('design-gallery')}}
                                    </span>
                                    <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropGallery }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="white"/></svg>
                                </button>
                                <button v-if="dropGallery" @click="dropGallery = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                <div v-if="dropGallery" class="absolute top-9 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                    <ul class="">
                                        <li :class="{ current : galleryItem === galleryName }" class="hover:bg-bgBlue p-2.5 text-bgBlue hover:text-white rounded-md" 
                                        v-for="(galleryItem, index) in galleryList" :key="index">
                                            <a href="javascript:void(0)" @click="redirectBtn(galleryItem.link),dropGallery = !dropGallery" class="py-2.5 font-semibold" >{{ galleryItem.name }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="hidden md:block relative flex justify-center items-center w-full">
                                <button type="button" class="w-full flex items-center justify-center gap-2 hover:bg-opacity-50 hover:bg-bgBlue py-3"  @click="dropService = !dropService"
                                v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('sales-&-auction') || isActive('buy-&-rent') || isActive('custom-order') || isActive('order-now-art') || isActive('order-now-photo') || isActive('order-now-design') || isActive('exhibition') || isActive('exhibition-info') }">
                                    <!-- <span>{{ serviceName }}</span> -->
                                    <span v-if="!isActive('sales-&-auction') && !isActive('buy-&-rent') && !isActive('custom-order') && !isActive('order-now-art') &&!isActive('order-now-photo') && !isActive('order-now-design') && !isActive('exhibition') && !isActive('exhibition-info')">
                                    <!-- Servies -->{{$t('services')}}
                                    </span>
                                    <span v-if="isActive('sales-&-auction') ">
                                    <!-- Sales / Auction -->{{$t('sale-auction ')}}
                                    </span>
                                    <span v-if="isActive('buy-&-rent')"><!-- Buy / Rent -->{{$t('buy-rent ')}}</span>
                                    <span v-if="isActive('custom-order') || isActive('order-now-art') || isActive('order-now-photo') || isActive('order-now-design')">
                                    <!-- Custom Order -->{{$t('custom-order ')}}
                                    </span>
                                    <span v-if="isActive('exhibition') || isActive('exhibition-info')"><!-- Exhibition -->{{$t('exhibition ')}}
                                    </span>

                                    <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropService }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="white"/></svg>
                                </button>
                                <button v-if="dropService" @click="dropService = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                <div v-if="dropService" class="relative">
                                    <ul class="absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                        <li :class="{ current : serviceItem === serviceName }" class="hover:bg-bgBlue p-2.5 text-bgBlue hover:text-white rounded-md" v-for="(serviceItem, index) in serviceList" :key="index">
                                            <a href="javascript:void(0)" v-if="!serviceItem.isComingSoon" @click="redirectBtn(serviceItem.link),dropService = !dropService" class="py-2.5 font-semibold">{{ serviceItem.name }}</a>
                                            <a href="javascript:void(0)" v-if="serviceItem.isComingSoon" title="coming Soon..." class="py-2.5 font-semibold text-gray-400 cursor-not-allowed">{{ serviceItem.name }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div> 

                            <a href="javascript:void(0)" @click="redirectBtn('/crowd-funding')" active-class="active" class="hidden md:block nav-link w-full hover:bg-opacity-50 hover:bg-bgBlue py-3" >
                            <!-- Crowd Funding -->{{$t('crowd-funding ')}}
                            </a>
                            <a href="javascript:void(0)" @click="redirectBtn('/forum')" active-class="active" class="hidden md:block nav-link w-full hover:bg-opacity-50 hover:bg-bgBlue py-3" >
                            <!-- Forum -->{{$t('forum')}}
                            </a>
                            
                            <div class="relative w-full flex items-center justify-center" v-if="isAuthenticated">
                                <button type="button" class="flex items-center justify-center rounded-full cursor-pointer" @click="dropUser = !dropUser">
                                    <img :src="profile_image" class="w-8 h-8  border border-white hover:border-bgBlue rounded-full">
                                </button>
                                <button v-if="dropUser" @click="dropUser = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-20"></button>  
                                <div v-if="dropUser" class="absolute top-9 right-4 border bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-full z-30">
                                    <div class="flex flex-col uppercase">
                                        <router-link :to="{name:'seller-dashboard'}" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                            <!-- Seller Dashboad -->{{$t('seller-dashboard')}}
                                        </router-link>
                                        <a href="javascript:void(0)" @click="redirectBtn('/')" class="hover:bg-bgBlue px-2.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex" >
                                            <!-- Buyer Dashboard -->{{$t('buyer-dashboard')}}
                                        </a>
                                        <router-link :to="{name:'seller-profile'}" class="hover:bg-bgBlue px-2.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex" >
                                            <!-- Seller Profile -->{{$t('seller-profile')}}
                                        </router-link>
                                        <button type="button" @click="logout" v-if="isAuthenticated" class="nav-link hover:bg-opacity-50 text-red-600 border-red-50 hover:bg-red-100 px-2 py-2.5 rounded uppercase"><!-- Sign Out -->{{$t('sign-out')}}
                                        </button >
                                    </div>
                                </div>
                            </div>

                            <router-link :to="{name:'sign-in'}" v-if="!isAuthenticated" active-class="active" class="md:w-full hover:bg-opacity-50 hover:bg-bgBlue py-3 uppercase px-3 md:px-0">
                                <!--Sign In--> {{$t('sign-in')}}
                            </router-link >

                            <div class="block md:hidden">
                                <button type="button" @click="mobileHeader = !mobileHeader" class="px-3 md:px-0">
                                    <svg class="fill-current text-white w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
           
            
            <!-- mobile header -->
            <div class="md:hidden" v-if="mobileHeader">
                <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div>   
                    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 right-0 z-50 outline-none focus:outline-none">

                        <div class="flex w-full">
                            <div @click="mobileHeader = !mobileHeader" class="flex w-6/12"></div> 
                            <div class="w-6/12 flex relative">
                                <div class="w-full shadow-lg rounded-l-md h-screen relative flex  bg-white outline-none focus:outline-none mx-auto border-l-2 border-y-2 border-blue-200 relative">        
                                    <div class="flex flex-col gap-4 px-5 py-2">
                                        <a href="javascript:void(0)" @click="redirectToClient()" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isHomePage == 1}]"><!-- Home -->{{ $t('home') }}</a>
                                        <a href="javascript:void(0)" @click="redirectBtn('/search')" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isSearchPage == 1}]"><!-- Search -->{{$t('search')}}</a>
                                        <div class="px-2 py-1.5 rounded cursor-pointer">
                                            <div class="flex items-center gap-2 text-black" 
                                            @click.native="dropGallery = !dropGallery"
                                            v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('art-gallery') || isActive('art-users') || isActive('art-user-profile') || isActive('photo-gallery') || isActive('photographer-list') || isActive('photographer-profile') || isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile') || isActive('designers-gallery') || isActive('designers-list') || isActive('designers-profile')}">
                                               <span v-if="!isActive('art-gallery') && !isActive('art-user-profile') && !isActive('photo-gallery') && !isActive('photographer-profile') && !isActive('frame-gallery') && !isActive('frame-vendors') && !isActive('frame-vendor-profile') && !isActive('designers-gallery') && !isActive('designers-profile')"><!-- Gallery -->{{$t('gallery ')}}</span>
                                                <span v-if="isActive('art-gallery') || isActive('art-user-profile')"><!-- Art Gallery -->{{$t('art-gallery ')}}</span>
                                                <span v-if="isActive('photo-gallery') || isActive('photographer-profile')"><!-- Photo Gallery -->{{$t('photo-gallery ')}}</span>
                                                <span v-if="isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile')"><!-- Frame Gallery -->{{$t('frame-gallery')}}</span>
                                                <span v-if="isActive('designers-gallery') || isActive('designers-profile')"><!-- Design Gallery -->{{$t('design-gallery')}}</span>
                                                
                                                <svg class="w-2 lg:w-3.5 fill-current hover:text-white text-black flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropGallery }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill=""/></svg>
                                            </div>
                                            <button v-if="dropGallery" @click="dropGallery = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                            <div v-if="dropGallery" class="relative">
                                                <ul class="border bg-gray-200 absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                                    <li :class="{ current : galleryItem === galleryName }" class="nav-link hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="(galleryItem, index) in galleryList" :key="index" @click="selectGallery(galleryItem.name)">
                                                        <a href="javascript:void(0)" @click="redirectBtn(galleryItem.link)" class="py-2 font-semibold">{{ galleryItem.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="hover:bg-bgBlue px-2 py-1.5 rounded cursor-pointer">
                                            <div class="flex items-center gap-2 text-black" v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('sales-&-auction') || isActive('buy-&-rent') || isActive('custom-order') || isActive('exhibition') || isActive('exhibition-info') }" @click="dropService = !dropService">
                                                <span v-if="!isActive('sales-&-auction') && !isActive('buy-&-rent') && !isActive('custom-order') && !isActive('exhibition') && !isActive('exhibition-info')"><!-- Servies -->{{$t('services')}}</span>
                                                <span v-if="isActive('sales-&-auction') "><!-- Sales / Auction -->{{$t('sale-auction ')}}</span>
                                                <span v-if="isActive('buy-&-rent')"><!-- Buy / Rent -->{{$t('buy-rent ')}}</span>
                                                <span v-if="isActive('custom-order')"><!-- Custom Order -->{{$t('custom-order ')}}</span>
                                                <span v-if="isActive('exhibition') || isActive('exhibition-info')"><!-- Exhibition -->{{$t('exhibition ')}}</span>
                                                <svg class="w-2 lg:w-3.5 fill-current hover:text-white text-black flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropService }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill=""/></svg>
                                            </div>
                                            <button v-if="dropService" @click="dropService = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                            <div v-if="dropService" class="relative">
                                                <ul class="border bg-gray-200 absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                                    <li :class="{ current : serviceItem === galleryName }" class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="(serviceItem, index) in serviceList" :key="index" @click="selectService(serviceItem.name)">
                                                        <a href="javascript:void(0)" v-if="!serviceItem.isComingSoon" @click="redirectBtn(serviceItem.link)" class="py-2 font-semibold">{{ serviceItem.name }}</a>
                                                        <a href="javascript:void(0)" v-if="serviceItem.isComingSoon" title="coming Soon..." class="py-2.5 font-semibold text-gray-400 cursor-not-allowed">{{ serviceItem.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)" @click="redirectBtn('/crowd-funding')" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isCrowdFunding}]">
                                        <!-- Crowd Funding -->{{$t('crowd-funding ')}}
                                        </a>
                                        <a href="javascript:void(0)" @click="redirectBtn('/forum')" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isForum}]"><!-- Forum -->{{$t('forum')}}</a>
                                    </div>

                                </div>

                                <button type="button" @click="mobileHeader = !mobileHeader" class="absolute top-2 right-0 w-10 h-10">
                                    <svg class="ill-current text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                </button>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </header>
        
    </div>
    <div v-if="isConfirm">
        <Confirmation 
            :title="'confirmation-to-redirect'"
			:message="'you-are-being-redirected-to-buyer-side-want-to-continue'"
			@closeModelBox = "closeModelBox"
			@deleteRecord = "confirmedBtn" 
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Confirmation from "./DeleteRecordModelBox.vue";
import localforage from 'localforage';
import { isEmpty } from 'lodash';
export default  ({
    props:[
        'isSearchPage',
        'isCardid',
        'isArtGallery',
    ],
    data: function() {
        return {
            isAuthenticated: false,
            // isArtGallery: false,
            mobileHeader: false,        
            // galleryName: 'Gallery',
            galleryList: [
                {name:'Art Gallery', link: '/art-gallery'},
                {name:'Photo gallery', link:'/photo-gallery'},
                {name:'Frame Gallery', link:'/frame-gallery'},
                {name:'Design Gallery', link:'/designers-gallery'},
            ],
            dropGallery: false, 

            // serviceName: 'Servies',
            serviceList: [
                {name:'Sale / auction', link:'/sales-and-auction', isComingSoon : false},
                {name:'Buy / Rent', link:'/buy-and-rent' , isComingSoon : false},
                {name:'Custom Order', link:'/custom-order' , isComingSoon : true},
                {name:'Exhibition', link:'/exhibition', isComingSoon : true},
            ],
            dropService: false, 
            intervalId: '',
            locale: localStorage.getItem("localforage/smartartclient/locale") ? localStorage.getItem("localforage/smartartclient/locale").replace(/['"]+/g, '') : 'en',
            languageName: (localStorage.getItem('selectedLanguage') == '' || localStorage.getItem('selectedLanguage') === 'null') ? 'English' : localStorage.getItem('selectedLanguage'),
            languages: [],
            dropLang: false,
            dropUser: false,
            profile_image: '/images/user.webp',
            buyerBaseUrl: process.env.VUE_APP_SMART_ART_BUYER_URL,
            isConfirm:false,
            url:'',
            recruitClientUrl: process.env.VUE_APP_RECRUIT_URL,
            jiipaClientUrl: process.env.VUE_APP_JIIPA_URL,
            sekaiIchi: process.env.VUE_APP_SEKAI_ICHI_URL + 'product.php',
            plannerUrl: process.env.VUE_APP_PLANNER_URL,
        }
    },
    components: {
        Confirmation
    },
    computed: {
        ...mapGetters({
        }),
           
    },
    methods: {
         ...mapMutations({
        }),
        /*selectGallery(gallery) {
            this.galleryName = gallery;
        },*/
        /*selectService(option) {
            this.serviceName = option;
        },*/
        
        async getLanguages(){
            try{
                const res = await this.$store.dispatch("getLanguages",{});
                if(res.success){
                    this.languages = res.data;
                }
            }catch(e){
                console.log(e);
            }
        },

        selectLang(lang_slug,lang_name) {
            this.locale = lang_slug;
            this.languageName = lang_name;
            localStorage.setItem('selectedLanguage', lang_name);
            localforage.setItem('locale', this.locale);
            window.location = window.location.href
        },

        isActive(routeName) {
          return this.$route.name === routeName;
        },

        async getSiteSettingsClient(){
            clearInterval(this.intervalId);
            this.gIndicator('start');
            try{
                const res = await this.$store.dispatch("getSiteSettingsClient");
                let applicable_currency = res.data.applicable_currency || 'USD';
                localStorage.setItem("applicable_currency", applicable_currency);
            } catch(error){
                console.log("Error in response", error);
            }finally{
                this.gIndicator('stop');
            }
        },

         async getProfileDetails(){
            let emailid = localStorage.getItem('email');
            const payload = {
                email : emailid
            }
            try{
                const res = await this.$store.dispatch("myProfile/getProfileDetails",{payload: payload});
                let resDetails = res.details;
                if(resDetails && resDetails.profile_image){
                    this.profile_image = resDetails.profile_image;
                }
            } catch(error){
                console.log("Error in response", error);
            }finally{
               
            }
        },

        logout(){
            const email = localStorage.getItem('email');
            const password = localStorage.getItem('password');
            const keepMeLogin = localStorage.getItem('keepMeLoggedIn');
            const selectedLanguage = localStorage.getItem('selectedLanguage');
            const local = localStorage.getItem('localforage/smartartclient/locale');
            localStorage.clear();
            if(keepMeLogin){
                localStorage.setItem('email', email);
                localStorage.setItem('password', password);
                localStorage.setItem('keepMeLoggedIn', keepMeLogin);
            }
            localStorage.setItem('selectedLanguage', selectedLanguage);
            localStorage.setItem('localforage/smartartclient/locale', local);
            window.location.href = process.env.VUE_APP_SMART_ART_SELLER_URL+'/sign-in';
        },
        closeModelBox(){
            this.isConfirm = false;
            this.url = '';
        },
        redirectBtn(query){
            if(query === '/forum'){
                this.$router.push({name:'forum-list'});
            }else{
                this.isConfirm = true;
                this.url = query;
            }
            
        },
        confirmedBtn(){
            window.open(this.buyerBaseUrl+this.url);
            this.closeModelBox();
        },
        redirectToClient(){
            window.location.href = this.buyerBaseUrl;
        },
        async loadfunction(){
            clearInterval(this.intervalId);
            await this.getLanguages();
            this.isAuthenticated = this.isUserLoggedIn();
            if(this.isAuthenticated){
                await this.getProfileDetails();
                await this.getSiteSettingsClient();
            }
        
            const selectedLanguage = (localStorage.getItem('selectedLanguage') == '' || localStorage.getItem('selectedLanguage') === 'null') ? 'English' : localStorage.getItem('selectedLanguage');
            this.languageName = selectedLanguage;
            localStorage.setItem('selectedLanguage', selectedLanguage);
        }
    },
   

    mounted() {
        this.intervalId = setInterval(() => {
            this.loadfunction();
        }, 1000);

         window.addEventListener('scroll', () => {
            const scroll_top = document.getElementById('scroll_top');
            if (scroll_top) {
                if (window.scrollY > 0) {
                    scroll_top.classList.remove('top-9', 'md:top-12', 'xl:top-16');
                    scroll_top.classList.add('top-0', 'shadow-xl');
                } else {
                    scroll_top.classList.remove('top-0', 'shadow-xl');
                    scroll_top.classList.add('top-9', 'md:top-12', 'xl:top-16');
                }
            }
        });
    },
    
});
</script>
<style scoped>
    .active {
        background: #022770;
        color: #fff;
    }
    .activeCart {
        background: #022770;
        border-radius: 50px;
        color: #fff;
    }

    .galActive {
      background-color: blue;
    }


</style>