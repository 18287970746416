export default [
	{
		path: '/sign-in',
		component: () => import('../components/SignIn.vue'),
		name: 'sign-in',

	},
	{
		path: '/sign-up',
		component: () => import('../components/SignUpParent.vue'),
		children:[
			{
				path: '/sign-up',
				component: () => import('../components/SignUp.vue'),
				name: 'sign-up',

			},
			{
				path: '/sign-up/services',
				component: () => import('../components/partials/Services.vue'),
				name: 'optional-services',

			},
			{
				path: '/sign-up/payment',
				component: () => import('../components/partials/Payment.vue'),
				name: 'sign-up-payment',
			},
			{
				path: '/payment',
				component: () => import('../components/partials/Payment.vue'),
				name: 'payment',
			},
            {
				path: '/payment-renew',
				component: () => import('../components/partials/PaymentRenew.vue'),
				name: 'paymentrenew',
			},

            {
                path: "/success",
                name: "stripPaymentSuccess",
                component: () => import('../components/partials/stripPaymentSuccess.vue'),
                
            },

			{
				path: '/reset-password',
				component: () => import('../components/partials/ResetPassword.vue'),
				name: 'reset-password',
			},
			{
                path: "/paypal-error",
                name: "PaypalError",
                component: () => import('../components/partials/PaypalError.vue'),
                
            },
			{
                path: "/paypal-success",
                name: "PaypalSuccess",
                component: () => import('../components/partials/PaypalSuccess.vue'),
                
            },
			{
                path: "/payment-renew-success",
                name: "stripPaymentRenewSuccess",
                component: () => import('../components/partials/StripePaymentRenewSuccess.vue'),
                
            },
			{
                path: "/payment-renew-paypal-success",
                name: "paypalPaymentRenewSuccess",
                component: () => import('../components/partials/PaypalPaymentRenewSuccess.vue'),
                
            },
			{
                path: "/payment-renew-paypal-error",
                name: "paypalPaymentRenewError",
                component: () => import('../components/partials/PaypalPaymentRenewError.vue'),
                
            },
		]
		
	},
]