export const getSlackTranList = ({ commit, dispatch }, query = '') => {
    const getLSData = JSON.parse(localStorage.getItem("ls_data"));
    let seller_id = getLSData.id;
    query = query ? query+'&seller_id=' + seller_id : +'?seller_id=' + seller_id;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/transactions-list-slack'+ query).then((response) => {
        commit('SET_ST_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getDiskTranList = ({ commit, dispatch }, query = '') => {
    const getLSData = JSON.parse(localStorage.getItem("ls_data"));
    let seller_id = getLSData.id;
    query = query ? query+'&seller_id=' + seller_id : +'?seller_id=' + seller_id;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/transactions-list-disk'+ query).then((response) => {
        commit('SET_ST_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}