<template>
    <div class="relative space-y-4 " id="feedback-scroll">
        <img src="/images/feedback.webp" class="w-full h-[707px] 2xl:object-fit">
        <div class="absolute inset-y-0 w-full flex justify-center items-center">
            <div class="w-11/12 2xl:w-9/12 mx-auto my-6 pt-6 space-y-12">
                <div class="flex items-center justify-between gap-2">
                    <div class="md:w-[681px] border-b-2 border-white pb-4">
                        <label class="md:px-8 text-white font-semibold font-inter text-xl md:text-2xl lg:text-3xl xl:text-5xl uppercase">
                        <!-- Reviews & Feedback -->{{$t('reviews-and-feedback ')}}
                        </label>
                    </div>
                    <div class="flex w-[279px] h-[60px]">
                        <button @click="showAddFeedback(true)" class="w-full text-xs md:text-base text-white font-semibold flex items-center justify-center bg-bgReg shadow-btnShadow border border-white rounded-md uppercase transform hover:-translate-1 hover:scale-105 transition duration-100">
                        <!-- Add Your Feedback -->{{$t('add-your-feedback')}}
                        </button>
                    </div>
                </div>

                <!-- :autoplayInterval="3000" -->
                <div class="card relative">
                    <div v-if="!getFeedbackList || getFeedbackList.length === 0" class="flex justify-center items-center h-20 text-white font-semibold">
                        <!-- No data availble -->{{$t('no-data-available')}}
                    </div>
                    <div v-else :key="getFeedbackList">
                        <Carousel :value="getFeedbackList" :num-visible="2" :num-scroll="1" :responsive-options="responsiveOptions" 
                        :autoplayInterval="getFeedbackList.length > 1 ? 3000 : 0">
                            <!-- Custom Previous Button -->
                            <template #previousicon>
                                <button type="button" class="custom-nav-btn left-btn border-2 border-white rounded-full hover:bg-gray-800">
                                    <svg class="fill-current text-white hover:text-gray-200" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="none">
                                        <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/>
                                    </svg>
                                </button>
                            </template>

                            <!-- Custom Next Button -->
                            <template #nexticon>
                                <button type="button" class="custom-nav-btn right-btn border-2 border-white rounded-full hover:bg-gray-800">
                                    <svg class="fill-current text-white hover:text-gray-200" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="none">
                                        <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/>
                                    </svg>
                                </button>
                            </template>

                            <template #item="{data}">
                                <div class="lg:px-4 flex justify-center flex-col gap-4">
                                    <div class="px-2 w-full" style="display: flex; justify-content: center;">
                                        <div class="bg-white rounded-feedCardRadius w-full xl:w-[560px] h-[299px] h-full flex items-center justify-center gap-1">
                                            <div class="md:w-[150px] lg:w-[39%] xl:w-[150px] h-[259px] relative flex justify-center items-center pl-3 md:pl-0">
                                            <div class="bg-feedBg w-[52px] md:w-[100px] h-[239px] rounded-feedBorderRadius"></div>
                                            <div class="absolute inset-0 bg-yellowBg w-[80px] md:w-[126px] h-[199px] mx-auto my-auto rounded-feedBorderRadius">
                                                <div class="flex flex-col justify-between space-y-2 p-2">
                                                    <div class="h-14 md:h-28">
                                                        <img :src="data.profile_image ? data.profile_image_url : '/images/user.webp'" class="w-full h-full object-cover">
                                                    </div>
                                                    <div class="group flex justify-center h-8">
                                                        <label class="capitalize font-semibold text-sm truncate w-full text-center overflow-hidden text-ellipsis">{{data.first_name}} {{data.last_name}}</label>
                                                        <div class="transform:perspective(50px)_translateZ(0)_rotateX(10deg)] group-hover:[transform:perspective(0px)_translateZ(0)_rotateX(0deg)] absolute bottom-2 origin-bottom transform rounded text-white opacity-0 transition-all duration-300 group-hover:opacity-100">
                                                            <div class="flex max-w-xs flex-col items-center relative">
                                                                <svg class="absolute inset-y-0 -top-3.5" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m246-386 234-232 234 232H246Z"/></svg>
                                                                <label class="capitalize rounded bg-gray-900 p-2 text-xs text-white text-center shadow-lg"> {{data.first_name}} {{data.last_name}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label class="font-normal text-xs flex justify-center ">
                                                    <!-- Artist -->{{$t('artist ')}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-[70%] xl:w-[310px] h-[205px] space-y-6 pl-5 md:pl-0 md:px-8 lg:px-2 py-2">
                                            <div class="w-full h-[125px] overflow-auto">
                                                <p class="font-normal text-sm">
                                                    {{data.message}}
                                                </p>
                                            </div>
                                            <div class="flex">                                 
                                                <svg v-for="(n,i) in parseInt(data.star)" :key="i" class="text-yellowStar w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd"/>
                                                </svg>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                </div>
                            </template>
                        </Carousel>
                    </div>
                </div>

                <div class="flex justify-between">
                    <label class="flex"></label>
                    <label class="flex w-[180px] h-[60px]">
                        <a href="javascript:void(0)" @click="redirectBtn('/all-feedbacks')" class="w-full text-white font-semibold flex items-center justify-center bg-bgReg shadow-btnShadow border border-white rounded-md uppercase transform hover:-translate-1 hover:scale-105 transition duration-100">
                        <!-- View All -->{{$t('view-all')}}
                        </a>
                    </label>
                </div>
               
            </div>
        </div>
        
        <div v-if="getShowAddFeedback">
            <AddFeedback />
        </div>

        <div v-if="isConfirm">
            <Confirmation 
                :title="'confirmation-to-redirect'"
                :message="'you-are-being-redirected-to-buyer-side-want-to-continue'"
                @closeModelBox = "closeModelBox"
                @deleteRecord = "confirmedBtn" 
            />
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Carousel from "primevue/carousel";
import AddFeedback from './partials/AddFeedback.vue'
import Confirmation from "@/components/DeleteRecordModelBox.vue";
export default  ({
    data: function() {
        return {
            buyerBaseUrl: process.env.VUE_APP_SMART_ART_BUYER_URL,
            isConfirm:false,
            url:'',
           
            responsiveOptions:[
               {
                    breakpoint: '1024px',
                    numVisible: 2,
                    numScroll: 1
                },
                {
                    breakpoint: '768px',
                    numVisible: 1,
                    numScroll: 1
                },
                {
                    breakpoint: '480px',
                    numVisible: 1,
                    numScroll: 1
                }
            ],
        }
    },
    components: {
      AddFeedback,
      Carousel,
      Confirmation,
    },
    computed: {
     ...mapGetters({
        getShowAddFeedback: 'feedback/getShowAddFeedback',  
        getFeedbackList:'feedback/getFeedbackList',       
    }),

    },

    methods: {
        ...mapMutations({
            'showAddFeedback':'feedback/showAddFeedback',
        }),
         ...mapActions({
            fetchFeedbackList:'feedback/fetchFeedbackList',
        }),
         fetchData(){
            try{
                this.gIndicator('start');
                this.fetchFeedbackList().then((response) => {
                    if(response){
                        this.gIndicator('stop');
                    }
                })
                .catch((err) => {
                });
            }catch(error){
                this.gIndicator('stop');
            }
        },

        closeModelBox(){
            this.isConfirm = false;
            this.url = '';
        },
        redirectBtn(query){
            this.isConfirm = true;
            this.url = query;
        },
        confirmedBtn(){
            window.open(this.buyerBaseUrl+this.url);
            this.closeModelBox();
        },
    },
   

    mounted() {
        this.fetchData();
        
    },
    
});
</script>
<style>
    
</style>